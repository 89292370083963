.relative-div {
  position: "relative";
}

.placard {
  position: absolute;
  bottom: 0px;
  right: 0px;
  color: hsl(251, 100%, 50%);
  padding: 6px 8px;
  margin: 0px;
  font-size: 1.1em;
  border-radius: 8px;
  backdrop-filter: blur(2px);
  background-color: hsla(240, 100%, 10%, 0.2);
}

.placard-title {
  color: hsl(281, 100%, 50%);

  margin-top: 0em;
  margin-bottom: 0em;
  font-weight: bold;
  backdrop-filter: blur(2px);
  background-color: hsla(240, 100%, 10%, 0.2);
  padding: 2px 6px;
  margin-right: 0px;
}

.placard-bottom {
  color: hsl(258, 100%, 51%);

  margin-right: 3px;
  margin-bottom: 0em;
  margin-top: 0.25em;
  font-size: 0.9em;
  text-align: right;
}

.author-name {
  backdrop-filter: blur(2px);
  background-color: hsla(240, 100%, 10%, 0.3);
  padding: 1px 4px;
}

@media (max-width: 768px) {
  .placard {
    padding: 0.8em 0.5em;
  }
  .placard-title {
    font-size: 1em;
    padding: 0.2em 0.5em;
  }

  .author-name {
    font-size: 1em;
    padding: 0.2em 0.5em;
    margin-right: 10px;
  }
}
