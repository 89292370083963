.landing {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 2em 2em 1em;
  text-align: left;
  box-sizing: border-box;
}

.title {
  font-size: 15vw;
  line-height: 0.2;
}

.menu {
  font-size: 6vw;
}

.byline {
  font-size: 1.5em;
  margin-top: auto;
  font-style: italic;
}

@media (max-width: 1200px) {
  .title {
    font-size: 8em;
  }

  .menu {
    font-size: 4em;
  }

  .byline {
    font-size: 3em;
  }
}
