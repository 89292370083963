.landing {
  text-align: left;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 2em 2em 1em;
  display: flex;
}

.title {
  font-size: 15vw;
  line-height: .2;
}

.menu {
  font-size: 6vw;
}

.byline {
  margin-top: auto;
  font-size: 1.5em;
  font-style: italic;
}

@media (width <= 1200px) {
  .title {
    font-size: 8em;
  }

  .menu {
    font-size: 4em;
  }

  .byline {
    font-size: 3em;
  }
}

.relative-div {
  position: "relative";
}

.placard {
  color: #2f00ff;
  backdrop-filter: blur(2px);
  background-color: #0033;
  border-radius: 8px;
  margin: 0;
  padding: 6px 8px;
  font-size: 1.1em;
  position: absolute;
  bottom: 0;
  right: 0;
}

.placard-title {
  color: #ae00ff;
  backdrop-filter: blur(2px);
  background-color: #0033;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  padding: 2px 6px;
  font-weight: bold;
}

.placard-bottom {
  color: #5005ff;
  text-align: right;
  margin-top: .25em;
  margin-bottom: 0;
  margin-right: 3px;
  font-size: .9em;
}

.author-name {
  backdrop-filter: blur(2px);
  background-color: #0000334d;
  padding: 1px 4px;
}

@media (width <= 768px) {
  .placard {
    padding: .8em .5em;
  }

  .placard-title {
    padding: .2em .5em;
    font-size: 1em;
  }

  .author-name {
    margin-right: 10px;
    padding: .2em .5em;
    font-size: 1em;
  }
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: #4d2b8c;
  background-color: #00001a;
  font-family: Roboto, "sans-serif";
}
/*# sourceMappingURL=index.cd471593.css.map */
